import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import Slider from 'react-slick'

import SEO from '../components/seo'

class ProjectTemplate extends Component {

  render() {
    let project = this.props.data.wordpressWpProject

    const settings = {
      infinite: true,
      speed: 800,
      fade:true,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
    }

    return (
      <>
        <SEO title={he.decode(project.yoast_meta.yoast_wpseo_title)} bodyClass={project.slug} description={project.yoast_meta.yoast_wpseo_metadesc} />
        <section className='projects__item projects__item--single'>
          <Slider className='projects__slider' ref={slider => (this.slider = slider)} {...settings}>
            <>
              <div className='projects__slider-item' style={{backgroundImage: `url(${project.acf.featured_image.localFile.childImageSharp.original.src})`}} />
            </>
            { project.acf.gallery && project.acf.gallery.map((el, i) => (
              <>
                <div className='projects__slider-item' style={{backgroundImage: `url(${el.localFile.childImageSharp.original.src})`}} />
              </>
            ))}
          </Slider>
          <div className='projects__inner'>
            { project.acf.address &&
              <h1 className='projects__title' dangerouslySetInnerHTML={{ __html: project.acf.address }} />
            }
          </div>
        </section>
        <section className='project'>
          <div className='project__inner'>
            <div className='project__left'>
              <Fade bottom distance='30px'>
                { project.acf.short_description &&
                  <div className='project__short' dangerouslySetInnerHTML={{ __html: project.acf.short_description }} />
                }
                <div className='project__developer'>
                  { project.acf.built &&
                    <span>{project.acf.built}</span>
                  }
                </div>
              </Fade>
            </div>
            <div className='project__right'>
              <Fade bottom distance='30px'>
                { project.acf.full_description &&
                  <div className='project__full' dangerouslySetInnerHTML={{ __html: project.acf.full_description }} />
                }
              </Fade>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default ProjectTemplate

export const projectQuery = graphql`
  query ($id: String!) {
    wordpressWpProject(id: {eq: $id}) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        featured_image {
          ...original
        }
        address
        built
        short_description
        full_description
        gallery {
          ...original
        }
      }
    }
  }
`
